import React from "react"
import DeckglMap from "../components/Globals/DeckGLMap/DeckGLMap.js"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactSuccessPage = () => (
  // <div className="contact_success_background">
  //     <div className="contact_success_message">
  //     <SEO title="Contact Form Success" />
  //     <p>Thanks for reaching out. I'll get back to you.</p>
  //     </div>
  // </div>
  <div className="success_page">
    <div className="contact_background">
      <div className="contact_form_container">
        <div className="contact_form_container_2">
          <div className="contact_success_title_container">
            <h4>Thanks for reaching out. </h4>
            <h4> I'll get back to you soon.</h4>
          </div>
        </div>
      </div>
      <div className="map_container">
        <DeckglMap />
      </div>
    </div>
  </div>
)

export default ContactSuccessPage
