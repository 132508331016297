import React, { Component } from "react"

// import "mapbox-gl/dist/mapbox-gl.css"

// import { StaticMap } from "react-map-gl"
import ReactMapGL, { Marker } from "react-map-gl"

import DeckGL, { GeoJsonLayer, IconLayer, TextLayer } from "deck.gl"

import { GiPositionMarker } from "react-icons/gi"

class DeckglMap extends Component {
  state = {}

  initialViewState = {
    hover: {
      x: 0,
      y: 0,
      hoveredObject: null,
    },

    zoom: 5.0,
    minZoom: 5,
    maxZoom: 16,
    pitch: 0, // 0Â°, 45Â°, 60Â°
    bearing: 0,

    latitude: 51.5347,
    longitude: -4.0, // kings cross

    linecolor: [0, 141, 251, 255],
  }

  render() {
    return (
      <div className="DeckglMap">
        <DeckGL initialViewState={this.initialViewState} controller>
          <ReactMapGL
            mapboxApiAccessToken="pk.eyJ1IjoicmtkZWNrYXJkIiwiYSI6ImNqd3dnbWd4NTAzcWc0OW56MTZobDl6b28ifQ.I87AgUaf8KMYjBWmjuLDWA"
            mapStyle="mapbox://styles/rkdeckard/cjwxve98533xz1do2hkmbawmx"
          >
            <Marker
              className="map_marker"
              latitude={51.53605}
              longitude={-0.12513}
              offsetLeft={-30}
              offsetTop={-35}
            >
              <GiPositionMarker size={40} color="white" />
            </Marker>
          </ReactMapGL>
        </DeckGL>
      </div>
    )
  }
}

export default DeckglMap
